import "@src/assets/css/global.scss"
import "@src/assets/fonts/fonts.css"

import { AnimatePresence } from "framer-motion"
import React from "react"
import ReactDOM from "react-dom/client"

export const wrapPageElement = ({ element }) => (
  <AnimatePresence mode="wait">{element}</AnimatePresence>
)

export const shouldUpdateScroll = args => {
  return true
}

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
